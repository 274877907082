const TeamAction = {
  Create: "TeamCreate",
  Join: "TeamJoin",
  Get: "TeamGet",
  Success: "TeamSuccess",
  Update: "TeamUpdate",
  Fail: "TeamFail",
  Start: "TeamStart",
  RemoveContent: "TeamRemoveContent",
  LocalUpdate: "TeamLocalUpdate"
};

export { TeamAction };
export default TeamAction;
