import React from "react";
import { useAuth, useFirestore, useFunctions } from "db";

const spinner = "/icons/loader.svg";

const Loading = ({ error, small, children }) => {
  // this is the very first component, so load these modules
  useAuth(); // load the modules
  useFirestore();
  useFunctions();
  const message = error && error.message;
  return (
    <div className="text-center">
      <img
        alt=""
        src={spinner}
        style={{ width: small ? 64 : 192 }}
        data-role="Loading"
      />
      <div>{message}</div>
      {children}
    </div>
  );
};

export default Loading;
