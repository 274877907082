import { MessageActions } from "actions/message";

const MessageReducer = (state = {}, action) => {
  switch (action.type) {
    case MessageActions.Show: {
      const { style, content, title } = action.data;
      return { style, content, title };
    }
    case MessageActions.Hide:
      return {};
    default:
      return state;
  }
};

export default MessageReducer;
