import { batch } from "react-redux";

import { getFirestore } from "db";
import { Collection } from "db/Constants";
import store from "store";
import { ContentAction } from "actions/content/actionTypes";
import types from "actions/auth/types";
import { updateProfile } from "actions/auth/authenticate";
import loginAction from "actions/auth/loginAction";
import { TeamAction } from "actions/team/actionTypes";
import { showError } from "actions/message";

const listeners = {
  [Collection.Contents]: null,
  [Collection.Teams]: null
};

const dispatch = payload => store.dispatch(payload);

const withCollection = collection => getFirestore().collection(collection);

const remove = name =>
  typeof listeners[name] === "function" && listeners[name]();
const unsubscribe = () => Object.keys(listeners).map(remove);

const removeTeamOfUser = async profile => {
  profile.team = null;
  await updateProfile(profile);
  dispatch(loginAction(types.login.Logout));
};

const handleTeamChange = profile => ({ doc, type }) => {
  if (type === "removed" || !doc.exists) {
    // team has been deleted
    // #1. Update user's profile & remove team
    // #2. Show message
    dispatch(showError("Team deleted", "The team has been deleted."));
    removeTeamOfUser(profile);
    return;
  }
  const { users } = doc.data();
  const exists = users.find(user => user.title === profile.email);
  if (!exists) {
    // user has been removed
    // #1. Update user's profile & remove team
    // #2. Show message
    dispatch(
      showError("Access removed", "Your access to the team has been revoked.")
    );
    removeTeamOfUser(profile);
    return;
  }
  // some other changes in user
  // may be like addition/removal of channel access
  // #1. Update local state
  dispatch({
    type: TeamAction.Success,
    team: {
      id: doc.id,
      ...doc.data()
    }
  });
};

const handleContentChange = profile => ({ doc, type }) => {
  const data = {
    id: doc.id,
    ...doc.data()
  };
  if (data.hidden || type === "removed") {
    dispatch({ type: ContentAction.Remove, data });
    return;
  }
  if (type === "added") {
    dispatch({ type: ContentAction.Add, data });
  } else if (type === "modified") {
    dispatch({ type: ContentAction.Update, data });
  }
};

const subscribeTeam = profile => {
  const { code } = profile.team;
  const handle = handleTeamChange(profile);
  const listener = withCollection(Collection.Teams)
    .where("code", "==", code)
    .onSnapshot(snapshot => snapshot.docChanges().forEach(handle));
  listeners[Collection.Teams] = listener;
};

const subscribeContent = profile => {
  const { code } = profile.team;
  const handle = handleContentChange(profile);
  const listener = withCollection(Collection.Contents)
    .where("owner", "==", code)
    .onSnapshot(snapshot => {
      const docs = snapshot.docChanges();
      batch(() => {
        for (const snap of docs) {
          handle(snap);
        }
      });
    });
  listeners[Collection.Contents] = listener;
};

const subscribe = profile => {
  unsubscribe(); // remove old listeners
  subscribeTeam(profile);
  subscribeContent(profile);
};

export { unsubscribe, subscribe };
