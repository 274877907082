import React from "react";
import { Row, Col } from "reactstrap";

const Page = ({ children }) => (
  <Row className="justify-content-center">
    <Col className="col-sm-8 col-md-6 col-lg-4">{children}</Col>
  </Row>
);

export default Page;
