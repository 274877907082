import React from "react";
import logo from "assets/icon-ottfeed.svg";

const Logo = ({ title, subTitle, description }) => (
  <div className="pb-2 pt-3 text-center">
    <img className="d-block mx-auto mt-2 mb-4" src={logo} alt="" width="70" />
    {!!title && <h4 className="mb-0">{title}</h4>}
    {!!subTitle && (
      <p className={`text ${!!description && "mb-0"}`}>{subTitle}</p>
    )}
    {!!description && <p className="text pt-0 mt-0">{description}</p>}
  </div>
);

export default Logo;
