import { combineReducers } from "redux";

import login from "reducers/auth/Login";
import forgot from "reducers/auth/Forgot";
import register from "reducers/auth/Register";

const authReducer = combineReducers({
  login,
  register,
  forgot
});

export default authReducer;
