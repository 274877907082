const defineAction = name => ({
  Add: `${name}Add`,
  Start: `${name}Start`,
  Update: `${name}Update`,
  Remove: `${name}Remove`,
  Fetch: `${name}Fetch`,
  Fail: `${name}Fail`,
  Select: `${name}Select`,
  Done: `${name}Done`,
  Success: `${name}Success`
});

const ContentAction = defineAction("contents");

export { ContentAction };
