import { loginWithEmail, logoutFromSystem } from "actions/auth/authenticate";
import types from "actions/auth/types";
import { ContentAction } from "actions/content/actionTypes";

type Credentials = {
  email: String,
  password: String,
  history: Object
};

const loginAction = (action: String, params: Credentials) => dispatch => {
  dispatch({ type: types.login.Start });
  switch (action) {
    case types.login.Login:
      loginWithEmail({
        ...params,
        dispatch
      });
      break;
    case types.login.Logout:
      dispatch({
        type: ContentAction.Success,
        data: []
      });
      logoutFromSystem({ dispatch });
      break;
    default:
    // nothing here
  }
};

export default loginAction;
