import types from "actions/auth/types";

const initial = {
  user: null,
  isBusy: false,
  error: null
};

const loginReducer = (state = initial, action) => {
  switch (action.type) {
    case types.login.Start:
      return {
        ...state,
        error: null,
        isBusy: true
      };
    case types.register.Success:
    case types.login.Success:
      return {
        isBusy: false,
        error: null,
        user: action.user
      };
    case types.login.Fail:
      return {
        ...state,
        isBusy: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default loginReducer;
