import React from "react";

import Page from "components/Page";
import Logo from "components/Logo";

const Maintenance = () => (
  <Page>
    <Logo
      title="OTTfeed is down for maintenance"
      subTitle={"We will be back in couple of hours."}
      description="Thank you for your patience."
    />
  </Page>
);

export default Maintenance;
