import firebase from "firebase/app";
import { useState, useEffect } from "react";

const config = {
  apiKey: "AIzaSyAEroCFmfcNLTaj9GU5ZzSSWNjIg3qjS70",
  authDomain: "feedms.firebaseapp.com",
  databaseURL: "https://feedms.firebaseio.com",
  projectId: "feedms",
  storageBucket: "feedms.appspot.com",
  messagingSenderId: "557218054302",
  appId: "1:557218054302:web:1d4ed9cea57e83cabebb8f",
  measurementId: "G-17QS5NC00D"
};

firebase.initializeApp(config);

const cached = {
  auth: null,
  firestore: null,
  functions: null,
  storage: null
};

const asyncLoadAndCache = async (impCall, name) => {
  if (cached[name]) {
    return cached[name];
  }
  await impCall();
  return (cached[name] = firebase[name]());
};

const useModule = (impCall, name) => {
  const [loadedModule, setModule] = useState(null);
  useEffect(() => {
    asyncLoadAndCache(impCall, name).then(setModule);
  }, [impCall, name]);
  return loadedModule;
};

const useAnalytics = () =>
  useModule(() => import("firebase/analytics"), "analytics");
const useAuth = () => useModule(() => import("firebase/auth"), "auth");
const useStorage = () => useModule(() => import("firebase/storage"), "storage");
const useFirestore = () =>
  useModule(() => import("firebase/firestore"), "firestore");
const useFunctions = () =>
  useModule(() => import("firebase/functions"), "functions");

const getModule = name => {
  if (cached[name] === null) {
    throw new Error(
      `You called getModule on module that has not been loaded yet. try using the module first with use${name}`
    );
  }
  return cached[name];
};

const getAuth = () => getModule("auth");
const getStorage = () => getModule("storage");
const getFirestore = () => getModule("firestore");
const getFunctions = () => getModule("functions");

export {
  getAuth,
  useAuth,
  getStorage,
  useStorage,
  useAnalytics,
  getFirestore,
  useFirestore,
  getFunctions,
  useFunctions
};
