import { getAuth } from "db";
import types from "actions/team/actionTypes";

const initial = {
  team: null,
  isBusy: false,
  channels: [],
  error: null
};

const getChannels = team => {
  try {
    const { email } = getAuth().currentUser;
    const member = team.users.find(user => user.title === email);
    return member.channels.map(ch => ({
      ...ch,
      id: ch.value
    }));
  } catch {
    // ignore this
  }
  return [];
};

const teamReducer = (state = initial, action) => {
  switch (action.type) {
    case types.Start:
      return {
        ...state,
        error: null,
        isBusy: true
      };
    case types.Success:
      return {
        ...state,
        isBusy: false,
        error: null,
        channels: getChannels(action.team),
        team: action.team
      };
    case types.Fail:
      return {
        ...state,
        isBusy: false,
        error: action.error
      };
    case types.LocalUpdate:
      return {
        ...state,
        subscription: action.subscription
      };
    default:
      return state;
  }
};

export default teamReducer;
