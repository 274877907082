import { combineReducers } from "redux";

import auth from "reducers/auth";
import content from "reducers/content";
import message from "reducers/message";
import team from "reducers/team";

export default combineReducers({
  auth,
  team,
  message,
  content
});
