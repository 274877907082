import { getFirestore } from "db";
import { Collection } from "db/Constants";

const PROFILE_KEY = "@@FMS_PROFILE";
const WELCOME_KEY = "@@FMS_SHOULD_SHOW_WELCOME";

const saveProfileLocally = profile =>
  localStorage.setItem(
    PROFILE_KEY,
    JSON.stringify({
      ...profile,
      __when: Date.now()
    })
  );

const getLocalProfile = () => {
  const profile = localStorage.getItem(PROFILE_KEY);
  if (profile) {
    return JSON.parse(profile);
  }
  return {};
};

const checkSubscriptionStatus = async teamCode => {
  const db = getFirestore();
  const team = await db
    .collection(Collection.Teams)
    .doc(teamCode)
    .get();
  const subscription = team.data().subscription;
  return (
    subscription &&
    subscription.items &&
    subscription.items.find(item => item.plan.active) !== undefined
  );
};

const needsProfileRefresh = async user => {
  if (
    !user.profile ||
    user.email !== user.profile.email ||
    !user.profile.team
  ) {
    return true;
  }
  if (
    user.profile.__when !== undefined &&
    user.profile.__when > Date.now() - 1000 * 60 * 60 * 24
  ) {
    return true;
  }
  const hasActiveSubscription = await checkSubscriptionStatus(
    user.profile.team.code
  );
  return !hasActiveSubscription;
};

const clearLocalProfile = () => localStorage.removeItem(PROFILE_KEY);
const setShouldShowWelcome = shouldShowWelcome => {
  if (shouldShowWelcome) {
    localStorage.setItem(WELCOME_KEY, "yes");
  } else {
    localStorage.removeItem(WELCOME_KEY);
  }
};

const shouldShowWelcome = () => localStorage.getItem(WELCOME_KEY) === "yes";

const isSubscriptionFeatureAvailable = profile =>
  profile.id !== undefined /** make sure profile has been loaded */ &&
  profile.subFeatureEnabled === undefined;

export {
  getLocalProfile,
  clearLocalProfile,
  saveProfileLocally,
  needsProfileRefresh,
  setShouldShowWelcome,
  shouldShowWelcome,
  isSubscriptionFeatureAvailable
};
