import React from "react"; // eslint-disable-line
import { Alert } from "reactstrap";
import { connect } from "react-redux";

import messageAction, { MessageActions } from "actions/message";

const Message = ({ style, title, content, dismiss }) => {
  return (
    <div className="message">
      <Alert color={style} isOpen={!!content} toggle={dismiss}>
        {!!title && <h5 className="alert-heading">{title}</h5>}
        <span>{content}</span>
      </Alert>
    </div>
  );
};

export default connect(
  ({ message }) => ({ ...message }),
  dispatch => ({
    dismiss: () => dispatch(messageAction(MessageActions.Hide))
  })
)(Message);
