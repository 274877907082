import types from "actions/auth/types";

const initial = {
  isBusy: false,
  error: null
};

const forgotReducer = (state = initial, action) => {
  switch (action.type) {
    case types.forgot.Start:
      return {
        error: null,
        isBusy: true
      };
    case types.forgot.Success:
      return {
        isBusy: false,
        error: null
      };
    case types.forgot.Fail:
      return {
        isBusy: false,
        error: action.error
      };
    default:
      return state;
  }
};

export default forgotReducer;
