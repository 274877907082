import { Collection } from "db/Constants";
import { getFirestore, getAuth } from "db";
import types from "actions/auth/types";
import { saveProfileLocally, clearLocalProfile } from "actions/auth/utils";
import { showError, showSuccess } from "actions/message";
import { unsubscribe } from "utils/realtime";

const registerWithEmail = async ({
  email,
  history,
  password,
  dispatch,
  ...profile
}) => {
  try {
    const db = getFirestore();
    const auth = getAuth();
    const { user } = await auth.createUserWithEmailAndPassword(email, password);
    profile.email = email;
    db.collection(Collection.Users)
      .doc(user.uid)
      .set(profile);
    user.profile = profile;
    user.profile.id = user.uid;
    dispatch({ type: types.register.Success, user });
    saveProfileLocally(user.profile);
    setTimeout(() => {
      history.push({
        pathname: "/"
      });
    }, 100);
  } catch (exp) {
    dispatch(showError("Signup failed", exp.message));
    dispatch({ type: types.register.Fail, error: exp.message });
  }
};

const getProfile = async email => {
  const db = getFirestore();
  const profiles = await db
    .collection(Collection.Users)
    .where("email", "==", email)
    .limit(1)
    .get();
  if (!profiles.docs.length) {
    throw new Error("The profile was not found.");
  }

  const profile = profiles.docs[0];
  const subscription = await profile.ref
    .collection(Collection.Subscriptions)
    .get();

  const subscriptions = [];
  let plan;
  for (const sub of subscription.docs) {
    const subInfo = sub.data();
    if ((plan = subInfo.items.find(item => item.plan.active))) {
      subscriptions.push(plan);
    }
  }

  return {
    subscriptions,
    ...profile.data()
  };
};

const updateProfile = async profile => {
  const db = getFirestore();
  await db
    .collection(Collection.Users)
    .doc(profile.id)
    .update(profile);
};

const loginWithEmail = async ({ email, password, history, dispatch }) => {
  try {
    const auth = getAuth();
    const { user } = await auth.signInWithEmailAndPassword(email, password);
    const profile = await getProfile(email);
    profile.id = user.uid;
    dispatch({
      type: types.login.Success,
      user: {
        email,
        profile
      }
    });
    saveProfileLocally(profile);
    history.push({ pathname: "/" });
  } catch (exp) {
    dispatch(showError("Login failed", exp.message));
    dispatch({
      type: types.login.Fail,
      error: exp.message
    });
  }
};

const logoutFromSystem = async ({ dispatch }) => {
  const auth = getAuth();
  try {
    unsubscribe(); // remove listeners
    await auth.signOut();
    dispatch({ type: types.login.Success, user: null });
    clearLocalProfile();
    localStorage.removeItem("search_options");
  } catch (exp) {
    dispatch(showError("Logout failed", exp.message));
    dispatch({ type: types.login.Fail, message: exp.message });
  }
};

const sendResetEmail = async ({ dispatch, email, history }) => {
  const auth = getAuth();
  try {
    await auth.sendPasswordResetEmail(email);
    dispatch(
      showSuccess(
        "Link sent",
        "A link to reset the password has been mailed to you."
      )
    );
    dispatch({ type: types.forgot.Success });
    history.push({ pathname: "/" });
  } catch (exp) {
    dispatch(showError("Error occurred", exp.message));
    dispatch({ type: types.forgot.Fail, message: exp.message });
  }
};

export {
  saveProfileLocally,
  registerWithEmail,
  logoutFromSystem,
  sendResetEmail,
  loginWithEmail,
  updateProfile,
  getProfile
};
