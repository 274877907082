const LoginAction = {
  Fail: "LoginActionsFail",
  Start: "LoginActionsStart",
  Login: "LoginActionsLogin",
  Logout: "LoginActionsLogout",
  Success: "LoginActionsSuccess"
};

const RegisterAction = {
  Fail: "RegisterActionsFail",
  Start: "RegisterActionsStart",
  Success: "RegisterActionsSuccess",
  Register: "RegisterActionsRegister"
};

const ForgotAction = {
  Fail: "ForgotActionsFail",
  Start: "ForgotActionsStart",
  Success: "ForgotActionsSuccess",
  Forgot: "ForgotActionsForgot"
};

const authActionTypes = {
  login: LoginAction,
  forgot: ForgotAction,
  register: RegisterAction
};

export default authActionTypes;
