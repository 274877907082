import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "db";
import store from "store";
import authActionTypes from "actions/auth/types";
import {
  getLocalProfile,
  needsProfileRefresh,
  saveProfileLocally
} from "actions/auth/utils";
import { getProfile } from "actions/auth/authenticate";

const useLoggedInUser = () => {
  const [mUser, setUser] = useState({
    isLoggedIn: false,
    _checking: true
  });
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!auth) {
      return;
    }
    const checkUser = async user => {
      if (!user) {
        setUser({
          _checking: false,
          isLoggedIn: !!user
        });
        return;
      }
      const storeUser = {
        email: user.email,
        profile: getLocalProfile()
      };
      if (await needsProfileRefresh(storeUser)) {
        try {
          const profile = await getProfile(storeUser.email);
          profile.id = user.uid;
          storeUser.profile = profile;
          saveProfileLocally(profile);
        } catch (exp) {
          // TODO: handle possible case
          console.log(exp);
        }
      }
      store.dispatch({
        type: authActionTypes.login.Success,
        user: storeUser
      });
      setUser({
        _checking: false,
        isLoggedIn: !!user,
        ...storeUser
      });
      const redirect = window.localStorage.getItem("@@fms_redirect");
      if (redirect) {
        setTimeout(() => {
          history.push(redirect);
        }, 100);
      }
    };
    window.__fms_login = auth.onAuthStateChanged(checkUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return mUser;
};

export default useLoggedInUser;
