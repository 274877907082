import { ContentAction } from "actions/content/actionTypes";

const initialState = {
  data: {},
  id: null, // the id of currently selected item
  isBusy: false,
  error: null
};

const contentReducer = (state = initialState, action) => {
  const existing = { ...state.data };
  switch (action.type) {
    case ContentAction.Start:
      return {
        ...state,
        isBusy: true,
        error: null
      };

    case ContentAction.Add:
      if (!action.data.id) {
        return state;
      }
      existing[action.data.id] = action.data;
      return {
        ...state,
        data: existing,
        id: action.data.id
      };

    case ContentAction.Remove:
      if (existing[action.data.id]) {
        delete existing[action.data.id];
      }
      return {
        ...state,
        data: existing
      };

    case ContentAction.Success:
      return {
        ...state,
        data: action.data.reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: item
          }),
          {}
        )
      };

    case ContentAction.Fail:
      return {
        ...state,
        error: action.error
      };

    case ContentAction.Update:
      if (!action.data.id) {
        return state;
      }
      existing[action.data.id] = action.data;
      return {
        ...state,
        data: existing
      };

    case ContentAction.Select:
      return {
        ...state,
        error: null,
        isBusy: false,
        id: action.id
      };
    case ContentAction.Done:
      return {
        ...state,
        isBusy: false
      };

    default:
      return state;
  }
};

export default contentReducer;
