import { useFirestore } from "db";
import { useEffect, useState } from "react";

const cachedData = {
  /**
   * Map of channel id to playlist server URL.
   */
  playlistChannels: {},
  /**
   * Map of channel id to QR code enabled status.
   */
  qrCodeEnabledTeam: {}
};

const hasCachedData = () => Object.keys(cachedData.playlistChannels).length > 0;
const useSiteConfig = () => {
  const [state, setState] = useState({
    loading: !hasCachedData(),
    ...cachedData
  });
  const fs = useFirestore();
  useEffect(() => {
    if (!fs || hasCachedData()) {
      return;
    }
    setState(currentState => ({ ...currentState, loading: true }));
    fs.collection("site_config")
      .doc("configuration")
      .get()
      .then(doc => {
        const data = doc.exists ? doc.data() : {};
        cachedData.playlistChannels = data.playlists || {};
        cachedData.qrCodeEnabledTeam = data.qrCodeEnabledTeam || {};
        cachedData.isModeOn = Boolean(data.maintenance);
        setState({
          loading: false,
          ...cachedData
        });
      })
      .catch(e => {
        console.log(e);
        setState(currentState => ({ ...currentState, loading: false }));
      });
  }, [fs]);
  return state;
};

export default useSiteConfig;
