import React from "react";
import { Provider } from "react-redux";
import asyncLoad from "react-loadable";
import { BrowserRouter } from "react-router-dom";

import Loading from "components/Loading";
import store from "store";
import useLoggedInUser from "screens/auth/useLoggedInUser";
import Message from "components/Message";
import useIsMaintenanceMode from "hooks/useIsMaintenanceMode";
import Maintenance from "components/maintenance/Maintenance";
import { useAnalytics } from "db";

const AuthScreen = asyncLoad({
  loader: () => import("screens/auth"),
  loading: Loading
});

const HomeScreen = asyncLoad({
  loader: () => import("screens"),
  loading: Loading
});

const AppRoute = () => {
  const user = useLoggedInUser();
  const { loading, isModeOn } = useIsMaintenanceMode();
  useAnalytics();

  if (isModeOn) {
    return <Maintenance />;
  }

  if (user._checking || loading) {
    return <Loading />;
  }

  const Screen = !user.isLoggedIn ? AuthScreen : HomeScreen;
  return (
    <>
      <Screen />
      <Message />
    </>
  );
};

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AppRoute />
    </BrowserRouter>
  </Provider>
);

export default App;
